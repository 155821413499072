<template>
  <div class="max-width app-container">
    <div
      v-for="environment in environments"
      :key="environment.name"
      class="app-endpoint"
    >
      <img class="pl-3" :src="require(`@/assets/svgs/network-ball.svg`)" />
      <div class="text-black">
        {{ getFQDN(environment) }}
        {{ environment.name }}
      </div>
      <v-btn
        v-if="environment.deployed"
        depressed
        icon
        class="back-btn"
        @click="viewEnvironment(environment)"
      >
        <v-icon color="gray"> mdi-eye </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Environment',
  props: {
    environments: {
      required: true,
    },
  },
  methods: {
    viewEnvironment(environment) {
      this.$emit('view', environment)
    },
    getFQDN(endpoint) {
      return endpoint.origin ? endpoint.origin.fqdn : endpoint.fqdn
    },
  },
}
</script>

<style lang="scss" scoped>
.add-button {
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.app-endpoint {
  display: grid;
  grid-template-columns: 1fr 9fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid #cccccc;
}
.app-endpoint:last-child {
  border-bottom: none;
}
.app-container {
  background: linear-gradient(180deg, rgba(226, 226, 226, 0) 0%, #ebebeb 100%);
  border: 1.5px solid #cccccc;
  box-sizing: border-box;
  color: #737a91;
  border-radius: 12px;
}
</style>
