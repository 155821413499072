<template>
  <div class="px-9 mt-5">
    <v-row class="mb-4">
      <v-col cols="6">
        <v-chip
          class="text-center d-block"
          color="green"
          dark
          style="border-radius: 8px;"
        >
          <v-icon size="medium" class="mr-2">mdi-check</v-icon>
          Deployed
        </v-chip>
      </v-col>
      <v-col cols="6">
        <v-chip
          class="text-center d-block"
          outlined
          style="border-radius: 8px;"
          @click="viewDeployApp"
        >
          View App
          <s-icon name="arrow-circle-right" class="ml-2" />
        </v-chip>
      </v-col>
    </v-row>
    <v-list-item-group class="mb-5">
      <v-divider />
      <v-list-item-content class="py-4 pl-0">
        <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">
          Name
        </s-text>
        <s-text weight="bold" size="lg" color="black">
          {{ environment.name }}
        </s-text>
      </v-list-item-content>
      <v-divider />
      <v-list-item-content class="py-4 pl-0">
        <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">
          Deploy url
        </s-text>
        <s-text weight="bold" size="md" color="black" class="truncate-text">{{ environment.deploy_info.http_infos[0].fqdn[0] }}</s-text>
      </v-list-item-content>
      <v-divider />
      <v-list-item-content v-if="environment.git_branch" class="py-4 pl-0">
        <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2"
          >Git Branch</s-text
        >
        <s-text weight="bold" size="lg" color="black">
          {{ environment.git_branch }}
        </s-text>
      </v-list-item-content>

      <v-list-item-content class="py-4 pl-0">
        <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2"
          >Environment Variables</s-text
        >
        <env-vars
          :envars="environment.envars"
          @showAppEnvVar="showEnvVar"
          @submit="addVar"
          @delete="deleteVar"
        />
      </v-list-item-content>
    </v-list-item-group>
    <s-btn @click="goBack" type="button" color="primary" elevation="0">
      OK
    </s-btn>
  </div>
</template>

<script>
import EnvVars from '@/components/apps/EnvVars'
export default {
  name: 'ViewEnvironment',
  components: {
    'env-vars': EnvVars,
  },
  props: {
    environment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fqdn() {
      return this.environment.origin
        ? `${this.environment.origin.fqdn}:${this.environment.origin.port}`
        : this.environment.fqdn
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    viewDeployApp() {
      window.open(`https://${this.environment.deploy_info.http_infos[0].fqdn[0]}`, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  border-radius: 6px;
  color: var(--v-gray-base);
  font-size: 14px;
}
</style>
