<template>
  <v-row class="ma-0">
    <v-col class="d-flex flex-column white pt-2">
      <v-row class="mx-2 mt-5">
        <v-col>
          <s-text-field
            name="name"
            label="Endpoint name"
            type="text"
            filled
            required
            v-model="value.name"
            :error-messages="getErrors('text', $v.value.name, 'Endpoint name')"
            @blur="$v.value.name.$touch()"
          >
          </s-text-field>
          <s-text-field
            name="description"
            label="Description"
            type="text"
            class="mb-n1"
            filled
            required
            v-model="value.description"
          >
          </s-text-field>
          <s-select
            label="Type"
            name="type"
            filled
            required
            :items="types"
            v-model="value.type"
            :error-messages="getErrors('text', $v.value.fqdn, 'Type')"
            @blur="$v.value.type.$touch()"
          />
          <v-row>
            <v-col>
              <s-text-field
                name="FQDN"
                label="FQDN"
                type="text"
                filled
                required
                v-model="value.fqdn"
                :error-messages="getErrors('fqdn', $v.value.fqdn, 'FQDN')"
                @blur="$v.value.fqdn.$touch()"
              >
              </s-text-field>
            </v-col>
            <v-col v-show="this.type === 'app'" cols="4">
              <s-text-field
                name="port"
                label="Port"
                type="text"
                filled
                required
                v-model="value.port"
                :error-messages="getErrors('port', $v.value.port, 'Port')"
                @blur="$v.value.port.$touch()"
              >
              </s-text-field>
            </v-col>
          </v-row>
          <s-btn
            @click="addEndpoints"
            type="button"
            color="primary"
            elevation="0"
          >
            Add
          </s-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import getErrors from '@/mixins/vuelidateErrors'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { url, porter } from '@/utils/validators'
export default {
  name: 'AppEndpointForm',
  mixins: [getErrors],
  props: {
    value: {
      type: Object,
      default: () => ({
        name: '',
        description: '',
        type: '',
        fqdn: '',
        port: '',
      }),
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    types() {
      return this.type === 'app' ? ['TCP', 'UDP'] : ['HTTP', 'TCP', 'UDP']
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    showAddEndpoint() {
      this.$emit('showAppEndpoint')
    },
    addEndpoints() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const payload = {
        description: this.value.description,
        name: this.value.name,
        type: this.value.type,
        origin: {
          port: this.value.port,
          fqdn: this.value.fqdn,
          ip: '',
        },
      }
      this.$emit('submit', payload)
      this.$router.go(-1)
    },
  },
  validations() {
    return {
      value: {
        name: { required },
        type: { required },
        fqdn: { required, url },
        port: {
          required: requiredIf(function () {
            return this.type === 'app'
          }),
          porter,
        },
      },
    }
  },
}
</script>
