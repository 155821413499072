<template>
  <div class="max-width app-container">
    <div
      v-for="endpoint in endpoints"
      :key="endpoint.name"
      class="app-endpoint"
    >
      <div class="status-tip">
        <img class="mr-2" :src="require(`@/assets/svgs/network-ball.svg`)" />
        {{ endpoint.is_public ? 'Public' : 'Private' }}
      </div>
      <div class="text-black">
        <!-- {{ getFQDN(endpoint) }} -->
        {{ endpoint.type + ' ' + endpoint.value }}
      </div>
      <!-- <v-btn depressed icon class="back-btn" @click="viewEndpoint(endpoint)">
        <v-icon color="gray"> mdi-eye </v-icon>
      </v-btn> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Endpoints',
  props: {
    endpoints: {
      required: true,
    },
  },
  methods: {
    viewEndpoint(endpoint) {
      this.$emit('view', endpoint)
    },
    getFQDN(endpoint) {
      return endpoint.origin ? endpoint.origin.fqdn : endpoint.fqdn
    },
  },
}
</script>

<style lang="scss" scoped>
.add-button {
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.app-endpoint {
  display: grid;
  grid-template-columns: 1fr 9fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid #cccccc;
}
.app-endpoint:last-child {
  border-bottom: none;
}
.app-container {
  background: linear-gradient(180deg, rgba(226, 226, 226, 0) 0%, #ebebeb 100%);
  border: 1.5px solid #cccccc;
  box-sizing: border-box;
  color: #737a91;
  border-radius: 12px;
}

.status-tip {
  padding: 4px 12px;
  background-color: #dcdcdc;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  margin: 0 12px;
}
</style>
