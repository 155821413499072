<template>
  <div class="max-width app-container">
    <div
      v-for="(envar, index) in envars"
      :key="`${envar.name}-${index}`"
      class="app-endpoint"
    >
      <img class="pl-2" :src="require(`@/assets/svgs/network-ball.svg`)" />
      <div class="text-black">
        {{ envar.name }}
      </div>
      <!-- <v-btn
        depressed
        icon
        class="back-btn"
        @click="deleteVar(index, envar.id)"
      >
        <v-icon color="gray"> mdi-trash-can-outline </v-icon>
      </v-btn> -->
    </div>
    <!-- <div @click="addVar" class="add-button cursor-pointer flex pl-2">
      <v-icon class="mr-4">mdi-plus</v-icon>
      Add ENV Variable
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'EnvVars',
  props: {
    envars: {
      required: true,
    },
  },
  methods: {
    addVar() {
      this.$emit('showAppEnvVar')
    },
    deleteVar(index, id) {
      this.$emit('delete', { index, id })
    },
  },
}
</script>

<style lang="scss" scoped>
.add-button {
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.app-endpoint {
  display: grid;
  grid-template-columns: 1fr 9fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid #cccccc;
}
.app-container {
  background: linear-gradient(180deg, rgba(226, 226, 226, 0) 0%, #ebebeb 100%);
  border: 1.5px solid #cccccc;
  box-sizing: border-box;
  color: #737a91;
  border-radius: 12px;
}
</style>
