import { ipRegexWithOrWithoutSubmask } from '@/utils/ipAddressValidation'
import { helpers } from 'vuelidate/lib/validators'

const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
)
const portPattern = new RegExp(
  '^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$'
)

export const required =
  (message = 'Required') =>
    (value) => {
      const result = !!value || message
      return result
    }

export const minLength =
  (minLen = 6, message) =>
    (value) =>
      value.length >= minLen || message || `At least ${minLen} characters`

export const match = (valueToMatch, message) => (value) =>
  value === valueToMatch || message || 'Two values must be equal'

export const email =
  (message = 'Invalid e-mail address') =>
    (value) => {
      const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return pattern.test(value) || message
    }

export const noWhitespace =
  (message = 'Whitespace is not allowed.') =>
    (value) => {
      return value.indexOf(' ') === -1 || message
    }
export const url = (value) => urlPattern.test(value)
export const port = (value) => portPattern.test(value)
export const porter = helpers.regex('porter', portPattern)

export const urls =
  (message = "Oops! That's an invalid url.") =>
    (urls) => {
      let isValid

      urls.forEach((url) => {
        isValid = urlPattern.test(url.trim())
      })

      return isValid || message
    }

export const ipaddr =
  (message = "Oops! That's an invalid IP address.") =>
    (ipaddrs) => {
      let isValid

      ipaddrs.forEach((ipaddr) => {
        isValid = ipRegexWithOrWithoutSubmask.test(ipaddr.trim())
      })

      return isValid || message
    }

export const minLengthAfterFirstInput =
  (len = 8, message = 'Min 8 characters') =>
    (value) =>
      value.length === 0 ||
    value.length >= len ||
    message ||
    `Min ${len} characters`
